iframe {
  width: 100%;
  height: 100vh;
  @include media("<=tablet") {
    height: 83vh;
  }
  @include media("<=736px") {
    height: 100vh;
  }
  @include media("<=420px") {
    height: 77vh;
  }
}



.page-content {
  padding: 120px 0 40px;
  color: #ffffff;
  
  @include media("<=430px") {
    padding: 100px 0 30px;
  }
}

.page-inner {
  padding-top: 30px;
  font-size: 24px;

  @include media("<=420px") {
    padding-top: 20px;
    font-size: 18px;
  }

  p {
    margin-bottom: 15px;
    line-height: 1.1;
    @include media("<=420px") {
      margin-bottom: 10px;
    }
  }
  h3 {
    line-height: 1.1;
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 20px;

    @include media("<=420px") {
      margin-bottom: 18px;
      font-size: 18px;
    }
  }
}
