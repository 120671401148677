.info {
    max-width: 370px;
    width: 100%;
    @include media("<=1080px") {
        max-width: 850px;
        width: 100% !important;
    }
  @include media("<=810px") {
    min-height: 760px;
  }
}

.info__inner {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 102px;
}



.info__item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
}

.info__image {
    flex: 0 1 100%;
    max-width: 54px;
    width: 100%;
    margin-right: 47px;
    @include media("<=430px") {
        margin-right: 15px;
    }
    img {
        width: 100%;
        display: block;
    }
}

.info__text {
    flex: 0 1 100%;
    width: 100%; 
    font-size: 20px;
    line-height: 1.2;
    @include media("<=430px") {
        
    }
}

.wrapper__slider {
    overflow: hidden;
    width: 100%;
    flex: 0 1 100%;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 90px;
    
    @include media("<=810px") {
        width: 100%;
        margin: 0 auto;
    }
    
}

.slider {
    position: relative;
    flex: 0 1 100%;
    width: 100%;
    z-index: 3;
    
    padding-bottom: 200px;
    @include media("<=1280px") {
        
    }

    @include media("<=1080px") {}

    @include media("<=810px") {
        padding-bottom: 120px;
    }

    @include media("<=430px") {
        
    }

    @include media("<=320px") {}

    .slick-slide {
        position: relative;
        cursor: pointer;
        transition: all 0.3s linear;
        @include media("<=810px") {
            
        }
    }

    .slick-list {
       // overflow: hidden;
        padding: 0 280px !important;
        @include media("<=1280px") {
          //  padding: 0 350px !important;
        }
        @include media("<=1080px") {
            padding: 0 200px !important;
          // padding: 0 50px !important;
        }
        @include media("<=810px") {
            padding: 0 50px !important;
            
        }
                @include media("<=430px") {
                    padding: 0 !important;
                }
    }

        .slick-track {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            overflow: hidden;
            column-gap: 125px;
            @include media("<=430px") {
                column-gap: 30px;
            }
        }
    
}

.slider__item {
    max-width: 514px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider__title {
    margin: 0 auto;
        text-align: center;
        font-size: 48px;
        text-transform: uppercase;
        font-family: 'BubbleGum';
        color: #FBF629;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}



