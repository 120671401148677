@font-face {
  font-family: 'BubbleGum';
  src: url("../fonts/BubbleGum.eot");
  src: local("☺"), url("../fonts/BubbleGum.woff") format("woff"),
    url("../fonts/BubbleGum.ttf") format("truetype"),
    url("../fonts/BubbleGum.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Mont';
  src: url("../fonts/Mont-Regular.eot");
  src: local("☺"), url("../fonts/Mont-Regular.woff") format("woff"),
    url("../fonts/Mont-Regular.ttf") format("truetype"),
    url("../fonts/Mont-Regular.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont-SemiBold.eot');
  src: url('../fonts/Mont-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Mont-SemiBold.woff') format('woff'),
    url('../fonts/Mont-SemiBold.ttf') format('truetype'),
    url('../fonts/Mont-SemiBold.svg#Mont-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont-Bold.eot');
  src: url('../fonts/Mont-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Mont-Bold.woff') format('woff'),
    url('../fonts/Mont-Bold.ttf') format('truetype'),
    url('../fonts/Mont-Bold.svg#Mont-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}