.boost {
    max-width: 423px;
    width: 100%;
}

.boost__inner {
    display: flex;
    flex-direction: column;
    padding-top: 54px;
    row-gap: 50px;
}

.boost__item {
    display: flex;
    align-items: center;
}

.boost__image {
    max-width: 54px;
    width: 100%;
    margin-right: 45px;
    @include media("<=430px") {
        margin-right: 20px;
    }
    img {
        width: 100%;
    }
}

.boost__text {
    font-size: 20px;
    line-height: 1.2;
    span {
        font-weight: 800;
        color: #FBF629;
    }
}