.disclaimer {
  padding-bottom: 76px;
  position: relative;

  @include media("<=430px") {

  }
  &-main {
    margin-top: -100px;
    @include media("<=430px") {
      margin-top: 0;
    }
  }
}

.disclaimer__inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media("<=810px") {
    padding: 20px 10px;
  
  }
  @include media("<=430px") {
    
  }
 
}

.disclaimer__text {
  line-height: 1.2;
  padding-top: 36px;
  padding-bottom: 45px;
  font-size: 24px;
  font-weight: 700;
  max-width: 879px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  @include media("<=810px") {
    padding-top: 25px;
    font-size: 20px;
  }
     @include media("<=430px") {
      font-size: 18px;
     }
}

.disclaimer__image {
  max-width: 508px;
  img {
    width: 100%;
  }
}

