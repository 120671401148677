.header {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #020345;
  width: 100%;
  padding: 30px 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 3;
  @include media("<=810px") {
    height: 80px;
    background-color: transparent;
    box-shadow: none;
    z-index: 20;
  }
  @include media("<=430px") {
    height: 70px;
  }
}

.header__inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.header__nav {
  max-width: 763px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @include media("<=1280px") {
    max-width: 560px;
  }
  @include media("<=810px") {
    display: none;

    &.active {
      max-width: 100%;
      display: flex;
      position: fixed;
      height: 40%;
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: #020345;
      z-index: 20;
      margin-right: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-content: space-between;
      align-items: center;
      opacity: 0.97;

      .header__link {
        padding: 20px;
        margin-left: 0;
        @include media("<=430px") {
          padding: 15px;
        }
      }
    }
  }
}

.header__link {
  font-size: 24px;
  font-weight: 900;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  @include media("<=1280px") {
    font-size: 20px;
  }
  @include media("<=810px") {
    font-family: 'Bungee', cursive;
    color: #ffffff;
    font-size: 20px;
  }

  &:hover {
    color: #DBFF00;
  }
  img {
    margin-right: 9px;
    @include media("<=810px") {
      display: none;
    }
  }
}

