.footer {
  padding: 60px 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  background-color: #020345;
  @include media("<=810px") {
    overflow: visible;
  }
  @include media("<=430px") {
    padding: 30px 0;
  }
  
}

.footer-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media("<=810px") {
    flex-direction: column;
  }
}

.footer__nav {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.footer-link {
font-family: 'BubbleGum';
  color: #FFFFFF;
    text-decoration: none;
    text-transform: uppercase;
    text-align: left;
    font-size: 36px;
   
    @include media("<=810px") {
      font-size: 28px;
      text-align: center;
    }
  
    @include media("<=430px") {
  
      margin-bottom: 10px;
     
    }
  
    &:hover {
      text-decoration: underline;
    }
  
  
}

.footer__content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 14px;
  @include media("<=810px") {
    align-items: center;
  }
}

.copyright {
  font-size: 12px;
  font-weight: 900;
  @include media("<=810px") {
    order: 3;
    padding-top: 10px;
    text-align: center;
  }
}

.footer__image {
  max-width: 339px;
  img {
    width: 100%;
  }
}