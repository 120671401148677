.about {
  flex: 0 1 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 128px;
  @include media("<=1080px") {
    
  }
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
    padding-bottom: 100px;
  }
}

.about__inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 2;
  column-gap: 10px;
  @include media("<=810px") {
    flex-direction: column-reverse;
  }
}

.about__content {
  flex: 0 1 100%;
  max-width: 586px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media("<=810px") {
    margin-bottom: 20px;
  }
}

.about__text {
  font-size: 20px;
  z-index: 2;
  line-height: 1.2;
  padding: 56px 0;
  @include media("<=1080px") {
    font-size: 18px;
  }
  @include media("<=810px") {
    font-size: 20px;
  }
  @include media("<=430px") {
    width: 100%;
    font-size: 18px;
  }
}

.about__image {
  max-width: 540px;
  flex: 0 1 100%;
  img {
    width: 100%;
    display: block;
  }
}

.about__pic {
  max-width: 508px;
  padding-top: 28px;
  img {
    width: 100%;
  }
}







