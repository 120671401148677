.features {
  position: relative;
  max-width: 514px;
  @include media("<=810px") {
    
  }
  @include media("<=430px") {
  }
  @include media("<=1280px") {
    max-width: 500px;
  }
}

.features__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 40px;
  row-gap: 34px;
  @include media("<=430px") {
    align-items: center;
    row-gap: 20px;
  }
}

.features__item {
  max-width: 100%;
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 300;
  @include media("<=430px") {
    font-size: 20px;
    flex-direction: column;
  }
}

.features__image {
  max-width: 56px;
  width: 100%;
  margin-right: 42px;
  @include media("<=430px") {
    margin-right: 0;
    margin-bottom: 15px;
    max-width: 40px;
  }
  img {
    width: 100%;
  }
}

.features__text {
  font-size: 20px;
  font-weight: 700;
  @include media("<=430px") {
    text-align: center;
    font-size: 18px;
  }
}






