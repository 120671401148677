.image__slider {
    width: 100%;
    overflow: hidden;
    background-image: url(../images/gallery-bcg.png);
    background-position: center bottom;
    background-repeat: no-repeat;
    @include media("<=1280px") {
     
    }

    @include media("<=1080px") {
       background-size: cover;
    }

    @include media("<=810px") {
      
    }

    @include media("<=430px") {
        
    }
    
}

.gallery__slider {
    padding: 30px 0 77px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    z-index: 3;
    overflow: hidden;
    @include media("<=1280px") {
        
    }

    @include media("<=1080px") {}

    @include media("<=810px") {
        
    }

    @include media("<=430px") {}

    @include media("<=320px") {}

    .slick-slide {
        position: relative;
        cursor: pointer;
        transition: all 0.3s linear;
        

        img {
            text-align: center;
            width: 100%;
        }
    }

    .slick-list {
        overflow: hidden;
        padding: 0 160px !important;
       
        @include media("<=810px") {
            
        }
                @include media("<=430px") {
                    padding: 0 30px !important;
                }
    }
        .slick-track {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 24px;
            @include media("<=810px") {
                flex-direction: row !important;
            }
        }
    
        .slider-item {
            margin: 0 2px;
            width: 100%;
    
            @include media("<=810px") {
              
            }
            img {
                width: 100%;
            }
        }

}




