.reviews {
    padding-bottom: 116px;
    overflow: hidden;

}

.reviews__slider {
    position: relative;
    margin: 0 auto;
    z-index: 3;
    padding: 60px 0 130px;

    @include media("<=1280px") {}

    @include media("<=1024px") {}

    @include media("<=810px") {}

    @include media("<=430px") {
        padding: 60px 0 80px;
    }

    @include media("<=320px") {}

    .slick-list {

        padding: 0 150px !important;
@include media("<=1080px") {
    padding: 0 100px !important;
}
        
        @include media("<=810px") {
            
        }

        @include media("<=430px") {
            padding: 0 15px !important;

        }
    }

    .slick-track {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 35px;
        @include media("<=810px") {
            justify-content: space-around;
        }
           @include media("<=430px") {
            justify-content: center;
            column-gap: 15px;
           }
    }

    .reviews__item {
        background: linear-gradient(180deg, rgba(24, 175, 207, 1) 0%, rgba(117, 66, 167, 1) 53%, rgba(56, 1, 112, 1) 100%);
        max-width: 448px;
        width: 100%;
        height: 371px;
        border-radius: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include media("<=1080px") {}

        @include media("<=430px") {
            padding: 10px;
            height: 320px;

        }
    }

    .reviews__title {
        flex: 0 1 auto;
        margin-bottom: 40px;
        width: 100%;
        font-size: 24px;
        font-weight: 900;
        text-transform: uppercase;
        color: #FBF629;
        z-index: 5;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        @include media("<=430px") {
            font-size: 22px;
            min-height: 40px;
            max-width: 360px;
        }

    }

    .reviews__text {
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        flex: 0 1 auto;
        max-width: 356px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        @include media("<=430px") {
            font-size: 18px;
        }
    }

    .reviews__image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-in-out;
        @include media("<=430px") {
            max-width: 200px;
        }
        img {
            @include media("<=430px") {
                width: 100%;
            }
        }
    }

    .slick-slide {
        position: relative;
        cursor: pointer;
        transition: all 0.3s linear;
    }

    .slick-current .reviews__text {
        opacity: 1;
    }
        .slick-current .reviews__title {
            opacity: 1;
        }
        .slick-current .reviews__image {
            opacity: 0;
        }
}

.slick-arrow {
    position: absolute;
    bottom: 0;
    z-index: 10;
    font-size: 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    width: 100%;
    max-width: 63px;
    max-height: 59px;
    height: 100%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s linear;

    &:hover {
        filter: drop-shadow(0px 2px 2px #980466);
    }

    @include media("<=810px") {}

    @include media("<=430px") {
        max-width: 42px;
        max-height: 38px;
    }

    @include media("<=375px") {}

    @include media("<=320px") {
        
    }

    &.slick-prev {

        background-image: url(../images/prev.png);
        left: 38%;

        @include media("<=1080px") {
            
        }

        @include media("<=810px") {
            left: 36%;
        }

        @include media("<=430px") {
            left: 32%;
        }

        @include media("<=320px") {}
    }

    &.slick-next {

        background-image: url(../images/next.png);
        right: 38%;

        @include media("<=1080px") {
            
        }

        @include media("<=810px") {
            right: 36%;
        }

        @include media("<=430px") {
            right: 32%;
        }

        @include media("<=320px") {}
    }
}

