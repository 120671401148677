.hero {
  position: relative;
  overflow-x: clip;
  background-image: url(../images/hero-bcg.png);
  background-position: 36% top;
  background-repeat: no-repeat;
  background-size: cover;
  @include media("<=1080px") {
  }
  @include media("<=810px") {
    z-index: 1;
    background-position: 80% top;
    background-size: 200%;

  }
    @include media("<=430px") {
      
    }
    .hero__links {
      @include media("<=810px") {
        position: absolute;
        bottom: 0;
      }
    }
}

.hero__inner {
  padding: 177px 0 222px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-end;
  column-gap: 14px;
  @include media("<=1280px") {
  }
  @include media("<=1080px") {
   
  }
  @include media("<=810px") {
    flex-direction: column;
    align-items: center;
    padding-top: 250px;
  }
  @include media("<=430px") {
    padding-bottom: 100px;
  }
}

.hero__content {
  flex: 0 1 100%;
  max-width: 400px;
  width: 100%;
  padding-bottom: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 4;
  @include media("<=810px") {
    max-width: 530px;
    z-index: 3;
    align-items: center;
    text-align: center;
   
  }
}

.hero__title {
  position: relative;
  margin-bottom: 18px;
  width: 100%;
  font-family: 'BubbleGum';
  font-weight: 700;
    color: #DBFF00;
    font-size: 128px;
  text-transform: uppercase;
  background: -webkit-radial-gradient(circle, #FF9900 0%, #FFF900 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  text-align: center;
  line-height: 1.2;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0 2px 2px #7542A7) drop-shadow(4px 0px 0px #380170);
  @include media("<=1280px") {
    
  }
    @include media("<=1080px") {
     font-size: 120px;
    
    }
  @include media("<=810px") {
    margin-bottom: 40px;
  }
  @include media("<=430px") {
    font-size: 92px;
  }

  @include media("<=330px") {
    font-size: 82px;
  }
  
}

.hero__link {
  flex: 0 1 100%;
  display: block;
  position: relative;
  z-index: 2;
  max-width: 253px;
  width: 100%;
  transition: 0.4s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &:hover {
    filter: drop-shadow(0 8px 15px #FAFF00);
  }
  img {
    width: 100%;
    display: block;
  }
  @include media("<=1080px") {
   
  }
  @include media("<=tablet") {
    
  }
  @include media("<=430px") {
    max-width: 230px;
    
    position: relative;
    z-index: 3;
  }
}

.hero__image {
  flex: 0 1 100%;
  position: relative;
  max-width: 384px;
  z-index: 3;
  @include media("<=1440px") {
  }
  @include media("<=1280px") {
    
  }
  @include media("<=1080px") {
   
  }
  @include media("<=810px") {
    display: none;
  }
  @include media("<=430px") {
  }

  img {
    width: 100%;
    display: block;
  }
}

.hero__pic {
  position: absolute;
  z-index: 5;
  @include media("<=810px") {
    z-index: 2;
  }
  &-1 {
    top: -20px;
    left: -8px;
    @include media("<=810px") {
      left: -10%;
    }
        @include media("<=430px") {
          left: -38%;
        }
  }
  &-2 {
    top: -5px;
    right: -14%;
    @include media("<=1280px") {
      top: -80px;
    }
        @include media("<=430px") {
          top: 0;
          right: auto;
          left: -40%;
          width: 250px;
        }
       
  }
  &-3 {
    bottom: 15%;
      right: -3%;
      @include media("<=1280px") {
        bottom: 10%;
      }
        @include media("<=810px") {
          bottom: 0;
        }
        @include media("<=430px") {
          right: -10%;
          max-width: 150px;
        }
  }
}